import { Typography } from '@material-tailwind/react';
import classNames from 'classnames';
import { capitalize, set } from 'lodash';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  elements,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import Button from 'src/app/ui/Button';
import Variants from 'src/app/ui/variants';
import styled from 'styled-components';
import React, { useEffect } from 'react';
import SelectInput from 'src/app/ui/SelectInput';
import { BENCHMARK } from 'src/app/core/consts/benchmark';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export interface Capacity {
  aspect: string;
  value: number;
}

const ProgressChart = ({ porcentage }: { porcentage: number }) => {
  const empty = 100 - porcentage;

  return (
    <svg width="80px" height="80px" viewBox="0 0 42 42" className="donut">
      <circle
        className="donut-ring"
        cx="21"
        cy="21"
        r="15.91549430918954"
        fill="transparent"
        stroke="rgba(255, 255, 255, 0.10)"
        strokeWidth="4"
      ></circle>
      {porcentage > 0 && (
        <circle
          className="donut-segment"
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          stroke="#0069FF"
          strokeWidth="4"
          strokeLinecap="round"
          strokeDasharray={`${porcentage} ${empty}`}
          strokeDashoffset="0"
        ></circle>
      )}
    </svg>
  );
};

const StyledDot = styled.span`
  display: block;
  width: 13.33px;
  height: 13.33px;
  border-radius: 50%;
`;

const StyledCard = styled.div.attrs({
  className: 'bg-white/5',
})`
  display: flex;
  padding: 16px 24px;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.04);
`;

const CapacityCard = ({ capacity, isQuizCompleted }: any) => {
  const backgrounds = [
    'bg-gray-500',
    'bg-red-200',
    'bg-orange-300',
    'bg-yellow-400',
    'bg-lime-400',
    'bg-green-500',
    'bg-green-500',
  ];

  const level = Math.ceil(capacity?.value / 20);

  if (!isQuizCompleted) {
    return (
      <StyledCard>
        <div className="flex items-center">
          <div className="relative mr-4">
            <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 tracking-tighter text-lg font-bold">
              0%
            </span>
            <ProgressChart porcentage={0}></ProgressChart>
          </div>
          <StyledDot className={backgrounds[0]} />

          <Typography
            variant="h5"
            className="ml-2 text-xl not-italic font-bold"
          >
            {capitalize(capacity.aspect)}
          </Typography>
        </div>
      </StyledCard>
    );
  }

  return (
    <StyledCard>
      <div className="flex items-center">
        <div className="relative mr-4">
          <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 tracking-tighter text-lg font-bold">
            {capacity.value}%
          </span>
          <ProgressChart porcentage={capacity.value}></ProgressChart>
        </div>
        <StyledDot className={backgrounds[level]} />

        <Typography variant="h5" className="ml-2 text-xl not-italic font-bold">
          {capitalize(capacity.aspect)}
        </Typography>
      </div>
    </StyledCard>
  );
};

const Capacities = (props: any) => {
  const { capacities, isFetching, isQuizCompleted } = props;
  const navigate = useNavigate();
  const [selectedRoles, setSelectedRoles] = React.useState<any>([]);
  const [selectedCapacities, setSelectedCapacities] = React.useState<any>([]);
  const labels = capacities
    ? capacities.map((c: any) => c.capacity)
    : [...Array(5)];
  const values = capacities
    ? capacities.map((c: any) => c.global_value)
    : [...Array(5)];

  const [data, setData] = React.useState<any>({
    labels: labels,
    datasets: [
      {
        label: 'My capacities',
        data: values,
        backgroundColor: 'rgba(255, 255, 255, 0.10)',
        borderColor: 'rgba(255, 255, 255, 0.80)',
        borderWidth: 3,
      },
    ],
  });

  useEffect(() => {
    if (!props.capacities) return;
    const labels = props.capacities
      ? capacities.map((c: any) => c.capacity)
      : [...Array(5)];
    setSelectedCapacities(labels);
    const values = capacities
      ? capacities.map((c: any) => c.global_value)
      : [...Array(5)];

    const roles = ['C_LEVEL', 'Director', 'Manager', 'Jefe'];
    const datasets = [];
    datasets.push({
      label: 'My capacities',
      data: values,
      backgroundColor: 'rgba(255, 255, 255, 0.10)',
      borderColor: 'rgba(255, 255, 255, 0.80)',
      borderWidth: 3,
    });

    // random number between 0 and 255

    roles.forEach((role: string) => {
      const benchmark = BENCHMARK[role as keyof typeof BENCHMARK];
      const capacities = labels.map((c: any) => {
        const capacity = benchmark.find((b: any) => b.name === c);
        return {
          capacity: c,
          value: capacity ? capacity.value * 20 : 50 + Math.random() * 50,
        };
      });
      data.datasets.filter((d: any) => roles.includes(d.label));
      const r = Math.floor(Math.random() * 256);
      const g = Math.floor(Math.random() * 256);
      const b = Math.floor(Math.random() * 256);
      const values = capacities.map((c: any) => c.value);
      datasets.push({
        label: role,
        data: values,
        backgroundColor: `rgba(${r}, ${g}, ${b}, 0.10)`,
        borderColor: `rgba(${r}, ${g}, ${b}, 0.80)`,
        borderWidth: 3,
        hidden: true,
      });
    });

    setData({
      labels: labels,
      datasets: datasets,
    });
  }, [props]);

  const options = {
    plugins: {
      legend: {
        display: true,
      },
      tooltips: {
        events: ['click'],
        onClick: (e: any) => {
          console.log(e);
        },
      },
    },
    scales: {
      r: {
        suggestedMin: 0,
        suggestedMax: 100,
        ticks: {
          display: false,
        },
        angleLines: {
          color: 'rgba(255, 255, 255, 0.20)',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.20)',
        },
        pointLabels: {
          color: 'white',
        },
      },
    },
  };

  /* const handleRoleChange = (evt: any) => {
    setSelectedRoles(evt);
    const roles = evt.map((r: any) => r.value);
    console.log(roles);
    const datasets = data.datasets.filter((d: any) => roles.includes(d.label));
    console.log(datasets);
  };*/

  return (
    <>
      <h2 className="text-3xl not-italic font-bold">Mis competencias</h2>

      <div className="flex justify-end">
        {/*  <div className="mb-6">
          <label
            className="block mb-2 text-sm text-gray-300"
            htmlFor="username"
          >
            Benchmark
          </label>

          <SelectInput
            isMulti
            id="roles"
            name="roles"
            size="lg"
            value={selectedRoles}
            onChange={handleRoleChange}
            placeholder="Seleccionar una opción"
            options={[
              { label: 'C Level (Level 1)', value: 'C_LEVEL' },
              { label: 'Director (Level 2)', value: 'Director' },
              { label: 'Manager (Level 3)', value: 'Manager' },
            ]}
          ></SelectInput>
        </div>
        */}
      </div>
      <div
        className={
          'my-5 flex justify-around gap-8 flex-wrap ' +
          (isQuizCompleted && isFetching ? 'animate-pulse' : '')
        }
      >
        <div className="flex justify-center w-full">
          <div className=" h-[600px] flex justify-center">
            {isQuizCompleted && data && <Radar data={data} options={options} />}
          </div>
        </div>
      </div>
      {!isQuizCompleted && (
        <div className="flex justify-around gap-8 flex-wrap mb-3 p-5 border border-tertiary bg-tertiary/30 rounded-lg">
          ¡Desbloquea tu máximo potencial! Realiza nuestro quiz digital para
          ayudarnos a entender tus necesidades y mejorar tus habilidades.
        </div>
      )}
      <div className="flex justify-start flex-wrap">
        <Button
          type="submit"
          chevron
          variant="secondary"
          onClick={() => {
            navigate('./selector');
          }}
        >
          Selecciona un Quiz
        </Button>
      </div>
    </>
  );
};

export default Capacities;
