import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import withNavbar from 'src/app/core/handlers/withNavbar';

const Profile: FunctionComponent<any> = () => {
  const pageContent = (
    <>
      <div className="my-5 container mx-auto">
        <h1 className="text-3xl font-bold">Profile</h1>
        <div className="mt-5">
          <Link to="/profile/change-password" className="text-blue-500">
            Change Password
          </Link>
        </div>
        <div className="mt-5">
          <Link to="/profile/manage-content" className="text-blue-500">
            Manage Content
          </Link>
        </div>
      </div>
    </>
  );

  return withNavbar({ children: pageContent });
};

export default Profile;
