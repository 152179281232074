import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Login from '../auth/handlers/Login';
import Details from '../feature-browser/handlers/Details';
import Browser from '../feature-browser/handlers/Browser';
import Navigator from '../feature-navigator/handlers/Navigator';
import Questionare from '../feature-navigator/handlers/Questionnaire/Questionare';
import Dashboard from '../feature-dashboard/handlers/Dashboard';
import Selector from '../feature-navigator/handlers/Questionnaire/Selector';
import Onboarding from '../feature-onboarding/handlers/Onboarding';
import ChangePassword from '../feature-profile/handlers/ChangePassword';
import Profile from '../feature-profile/handlers/Profile';
import Content from '../feature-content/handlers/Content';
import NewContent from '../feature-content/handlers/NewContent';
import path from 'path';
import Quiz from '../feature-quiz/handlers/Quiz';

const Routes = () => {
  // Define routes accessible only to auth users
  const routesForAuthUsers = [
    {
      path: '/',
      element: <ProtectedRoute />,
      children: [
        {
          path: '/onboarding',
          element: (
            <div className="min-h-screen flex flex-col">
              <Outlet />
            </div>
          ),
          children: [
            {
              path: '',
              element: <Onboarding />,
            },
          ],
        },
        {
          path: '/home',
          element: (
            <div className="min-h-screen flex flex-col">
              <Outlet />
            </div>
          ),
          children: [
            {
              path: '',
              element: <Dashboard />,
            },
          ],
        },
        {
          path: '/explorer',
          element: (
            <>
              <Outlet />
            </>
          ),
          children: [
            {
              path: '',
              element: <Browser />,
            },
            {
              path: ':id',
              element: <Details />,
            },
          ],
        },
        {
          path: '/diagnosticador',
          element: (
            <>
              <Outlet />
            </>
          ),
          children: [
            {
              path: '',
              element: <Navigator />,
            },
            {
              path: 'selector',
              element: <Selector />,
            },
            {
              path: 'questionare',
              element: <Questionare />,
            },
          ],
        },
        {
          path: '/profile',
          element: (
            <>
              <Outlet />
            </>
          ),
          children: [
            {
              path: '',
              element: <Profile />,
            },
            {
              path: 'change-password',
              element: <ChangePassword />,
            },
          ],
        },
        {
          path: 'content',
          element: (
            <>
              <Outlet />
            </>
          ),
          children: [
            {
              path: '',
              element: <Content />,
            },
            {
              path: 'new',
              element: <NewContent />,
            },
            {
              path: 'edit/:id',
              element: <NewContent />,
            },
          ],
        },
        {
          path: '/quiz',
          element: (
            <>
              <Outlet />
            </>
          ),
          children: [
            {
              path: ':id',
              element: <Quiz />,
            },
          ],
        },
        {
          path: '*',
          element: <Navigate to="home" />,
        },
      ],
    },
  ];

  // Routes accessbile only to non-authenticated users
  const routesForNonAuthUsers = [
    {
      path: '/login',
      element: <Login />,
    },
  ];

  const router = createBrowserRouter([
    ...routesForNonAuthUsers,
    ...routesForAuthUsers,
  ]);

  return <RouterProvider router={router} />;
};

export default Routes;
