import { Input } from '@material-tailwind/react';
import { useEffect, useState } from 'react';
import everythingIcon from '../../../../assets/icons/everything.svg';
import defaultIcon from '../../../../assets/icons/bot-icon.svg';
import manufacturingIcon from '../../../../assets/icons/manufacturing.svg';
import wellnessIcon from '../../../../assets/icons/wellness.svg';
import marketingIcon from '../../../../assets/icons/marketing.svg';
import hrIcon from '../../../../assets/icons/hr.svg';
import businessIcon from '../../../../assets/icons/business-services.svg';
import technologyIcon from '../../../../assets/icons/technology.svg';
import { useSearchParams } from 'react-router-dom';
import { set } from 'lodash';

const filters = [
  {
    value: 'Retail',
    image: manufacturingIcon,
    title: 'Retail',
  },
  {
    value: 'Wellness',
    image: wellnessIcon,
    title: 'Wellness',
  },
  {
    value: 'Information Technology',
    image: technologyIcon,
    title: 'Technology',
  },
  {
    value: 'Marketing Digital',
    image: marketingIcon,
    title: 'Marketing',
  },
  {
    value: 'Human Resources',
    image: hrIcon,
    title: 'HR & Talent',
  },
  {
    value: 'Consulting Services',
    image: businessIcon,
    title: 'Business Services',
  },
  /*
  {
    value: 'Cross-Industry',
    image: '',
    title: 'Cross-Industry',
  },
  {
    value: 'Financial Services',
    image: '',
    title: 'Financial Services',
  },
  {
    value: 'Media',
    image: '',
    title: 'Media',
  },
  {
    value: 'Health',
    image: '',
    title: 'Health',
  },
  {
    value: 'Retail',
    image: '',
    title: 'Retail',
  },
  {
    value: 'Logistics',
    image: '',
    title: 'Logistics',
  },
  {
    value: 'Energy',
    image: '',
    title: 'Energy',
  },
  */
];

const FilterButton = ({ image, active, onClick, children }: any) => {
  return (
    <div
      onClick={onClick}
      className={`flex flex-col justify-between items-center cursor-pointer py-4 px-2 ${
        active ? 'border-b-2 border-white' : 'opacity-70'
      }`}
    >
      <div className="mb-2">
        <img src={image ?? defaultIcon} alt={children} />
      </div>
      <h3 className="w-full overflow-hidden text-base text-center truncate">
        {children}
      </h3>
    </div>
  );
};

const SearchBar = ({
  handleSearch,
  handleFilter,
  activeFilter,
  searchTerm,
}: any) => {
  const [value, setValue] = useState('');
  let [searchParams, setSearchParams] = useSearchParams();

  const onChange = (event: any) => {
    setValue(event.target.value);
    handleSearch(event.target.value);
    setSearchParams({ search: event.target.value });
  };

  const goBack = () => {
    setValue('');
    handleSearch('');
    handleFilter('');
  };

  useEffect(() => {
    setValue(searchTerm);
  });

  return (
    <div className="flex flex-row-reverse items-center gap-5 sm:flex-row justify-between">
      <div className="flex items-center gap-4 sm:w-3/4 overflow-x-auto">
        <FilterButton
          onClick={goBack}
          image={everythingIcon}
          active={value === '' && activeFilter === ''}
        >
          Everything
        </FilterButton>
        {!value && (
          <div className="hidden lg:flex items-center gap-4">
            <div className="h-14 w-[1px] bg-white/20"></div>
            <div className="flex space-x-4 overflow-x-auto !scroll-p-0 pb-0">
              {filters.map((f: any, i: number) => (
                <FilterButton
                  key={i}
                  image={f.image}
                  active={f.value === activeFilter}
                  onClick={() => {
                    setSearchParams({ filter: f.value });
                    handleFilter(f.value);
                  }}
                >
                  {f.title}
                </FilterButton>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="w-full sm:w-1/4">
        <Input
          className="text-white"
          value={value}
          size="lg"
          label="Search..."
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default SearchBar;
