import { useQuery } from '@tanstack/react-query';
import { Link, useNavigate } from 'react-router-dom';
import api from 'src/app/core/api/apiProvider';
import { useUser } from 'src/app/core/feature-user/provider/userProvider';
import withNavbar from 'src/app/core/handlers/withNavbar';
import Alert from 'src/app/ui/Alert';
import Button from 'src/app/ui/Button';
import BotIcon from 'src/assets/icons/bot-icon.svg';
import Goals from '../components/Goals';

const DoughnutChart = (props: any) => {
  const { data } = props;
  return data ? (
    <svg width="150px" height="150px" viewBox="0 0 42 42" className="donut">
      <circle
        className="donut-hole"
        cx="21"
        cy="21"
        r="15.91549430918954"
        fill="transparent"
      ></circle>
      <circle
        className="donut-ring"
        cx="21"
        cy="21"
        r="15.91549430918954"
        fill="transparent"
        stroke="gray"
        strokeWidth="3"
      ></circle>

      {data.map((item: any, index: number) => (
        <circle
          key={index}
          className="donut-segment"
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          stroke={item.color}
          strokeWidth="6"
          strokeDasharray={`${item.value} ${100 - item.value}`}
          strokeDashoffset={item.offset}
        ></circle>
      ))}
    </svg>
  ) : null;
};

const DigitalADN = ({ query }: any) => {
  const navigate = useNavigate();
  const getCapacitiesComparison = () => {
    const capacities = query.data;
    if (capacities) {
      const prom = {
        operative: capacities.reduce((acc: any, capacity: any) => {
          return acc + capacity.details.operative;
        }, 0),
        strategic: capacities.reduce((acc: any, capacity: any) => {
          return acc + capacity.details.strategic;
        }, 0),
        tactic: capacities.reduce((acc: any, capacity: any) => {
          return acc + capacity.details.tactic;
        }, 0),
      };

      const data = [
        {
          name: 'Operative',
          color: '#50C6DF',
          value:
            (prom.operative * 100) /
            (prom.operative + prom.strategic + prom.tactic),
          offset: 25,
        },

        {
          name: 'Strategic',
          color: '#EFD385',
          value:
            (prom.strategic * 100) /
            (prom.operative + prom.strategic + prom.tactic),
          offset: 0,
        },
        {
          name: 'Tactic',
          color: '#A78BFF',
          value:
            (prom.tactic * 100) /
            (prom.operative + prom.strategic + prom.tactic),
          offset: 0,
        },
      ];

      //calculate offset
      data.forEach((item, index, arr) => {
        if (index > 0) {
          item.offset = 100 - (100 - item.value) + arr[index - 1].offset;
          return item;
        } else {
          item.offset = 25;
          return item;
        }
      });
      return data;
    }

    return null;
  };

  return query.isFetching ? (
    <div className="animate-pulse bg-gray-800 flex-grow rounded-xl p-7"></div>
  ) : (
    <div
      className="bg-gray-800 flex-grow rounded-xl p-7"
      onClick={() => {
        navigate('/diagnosticador');
      }}
    >
      <h2 className="text-xl font-semibold">ADN DIGITAL</h2>

      <div className="mt-6 flex justify-center gap-5 items-center">
        <DoughnutChart data={getCapacitiesComparison()} />
        <ul>
          {getCapacitiesComparison()?.map((item: any, index: number) => (
            <li key={index} className="flex items-center gap-2">
              <div
                className="w-3 h-3 rounded-full"
                style={{ backgroundColor: item.color }}
              ></div>
              <span>
                {item.value.toFixed(0)}% {item.name}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const Card = ({ isFetching, value, text }: any) => {
  const navigate = useNavigate();
  return isFetching ? (
    <div className="animate-pulse bg-gray-800 flex-grow rounded-xl p-7"></div>
  ) : (
    <div className="bg-gray-800 flex-grow rounded-xl p-7 flex flex-col justify-around">
      <div>
        <h2 className="text-5xl font-semibold">{value}</h2>
        <p className="text-base font-medium mt-1">{text}</p>
      </div>

      <div className="mt-6 justify-self-end">
        <Button
          outline
          onClick={() => {
            navigate('/diagnosticador');
          }}
        >
          <span className="text-base">Ver detalle</span>
        </Button>
      </div>
    </div>
  );
};
const ContentCard = ({ content }: any) => {
  return (
    <div className="bg-gray-800 flex-grow rounded-xl p-3">
      <h2 className="text-lg font-bold">{content.name}</h2>
      <p className="text-sm mt-1">{content.short_description}</p>

      <div className="mt-6">
        <span className="inline-block rounded-xl bg-primary-600 py-2 px-3 font-semibold text-sm">
          {content.type?.split('_').join(' ') ?? 'No type'}
        </span>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const navigate = useNavigate();
  const { userInfo, userAccountInfo } = useUser();

  const expertsQuery = useQuery({
    queryKey: ['expertsList'],
    queryFn: async () => {
      const { data } = await api.get(
        `${import.meta.env.VITE_API_URL}/diagnoses/recommend-experts`
      );
      return data;
    },
  });

  const contentsQuery = useQuery({
    queryKey: ['contentsList'],
    queryFn: async () => {
      const { data } = await api.get(
        `${import.meta.env.VITE_API_URL}/diagnoses/recommend-contents`
      );
      return data;
    },
  });

  const insightsQuery = useQuery({
    queryKey: ['insightsList'],
    queryFn: async () => {
      const { data } = await api.get(
        `${import.meta.env.VITE_API_URL}/diagnoses/retrieve-generated-insights`
      );
      return data;
    },
  });

  const goalsQuery = useQuery({
    queryKey: ['goals'],
    queryFn: async () => {
      const { data } = await api.get(`${import.meta.env.VITE_API_URL}/goals`);
      return data;
    },
  });

  const capacitiesQuery = useQuery({
    queryKey: ['capacitiessList'],
    queryFn: async () => {
      const { data } = await api.get(
        `${import.meta.env.VITE_API_URL}/diagnoses/capacities-comparisons`
      );
      return data;
    },
  });

  const getGeneralCapacitiesMean = () => {
    const capacities = capacitiesQuery.data;
    if (capacities) {
      const sum = capacities.reduce((acc: any, capacity: any) => {
        return acc + capacity.global_value;
      }, 0);

      return `${(sum / capacities.length).toFixed(0)}%`;
    }

    return '0%';
  };

  const getGoalsProgress = () => {
    const goals = goalsQuery.data;
    if (goals && goals.length > 0) {
      const totalGoals = goals.length;
      const doneGoals = goals.filter(
        (goal: any) => goal.status === 'done'
      ).length;
      return `${((doneGoals * 100) / totalGoals).toFixed(0)}%`;
    }
    return '0%';
  };

  const dispatcherContent = (
    <div className="container mx-auto pb-10">
      <div className="mt-6">
        <Alert image={BotIcon}>
          Hola {userAccountInfo?.public_name}! Gracias por acceder a Open KX 1ra
          generación. Actualmente nos encontramos en la fase de prelanzamiento.
          Cualquier feedback, reporte de problema o propuesta de mejora será MUY
          bienvenida.
          <a
            className="self-center"
            href="https://forms.gle/T2ELLU6vzwfC9RY9A"
            target="_blank"
          >
            <Button outline>Feedback</Button>
          </a>
        </Alert>
      </div>

      <h2 className="my-10 text-4xl font-semibold">Home - Mi Panel</h2>

      <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-3">
        <DigitalADN query={capacitiesQuery}></DigitalADN>
        <Card
          isFetching={capacitiesQuery.isFetching}
          value={'50%'}
          text="SCORE"
        />
      </div>
      <div className="mt-3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
        <Card
          isFetching={insightsQuery.isFetching}
          value={insightsQuery.data?.length}
          text="INSIGHTS GENERADOS"
        />
        <Card
          isFetching={capacitiesQuery.isFetching}
          value={getGeneralCapacitiesMean()}
          text="PROMEDIO GENERAL DE MADUREZ"
        />
        <Card
          isFetching={goalsQuery.isFetching}
          value={getGoalsProgress()}
          text="DE METAS DE EMPRESA CUMPLIDAS"
        />
      </div>

      <h2 className="mt-10 text-xl font-semibold">Metas de mi empresa</h2>
      <Goals />

      <h2 className="mt-10 text-xl font-semibold">Enlaces más populares</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 grid-sm">
        <div className="p-3 flex-1">
          <div
            className="bg-primary-700 p-3 rounded-lg text-sm font-bold text-center hover:bg-primary-800"
            onClick={() => {
              navigate('/diagnosticador/selector');
            }}
          >
            Agrega capacidades de tu interés a tu ADN Digital
          </div>
        </div>
        <div className="p-3 flex-1">
          <div
            className="bg-primary-700 p-3 rounded-lg text-sm font-bold text-center hover:bg-primary-800"
            onClick={() => {
              navigate('/explorer?filter=Marketing');
            }}
          >
            Accede a contenidos de Marketing Digital
          </div>
        </div>

        <div className="p-3  flex-1">
          <div
            className="bg-primary-700 p-3 rounded-lg text-sm font-bold text-center hover:bg-primary-800"
            onClick={() => {
              navigate('/explorer');
            }}
          >
            Conoce los contenidos más valorados por otros usuarios
          </div>
        </div>
        <div className="p-3 flex-1">
          <div
            className="bg-primary-700 p-3 rounded-lg text-sm font-bold text-center hover:bg-primary-800"
            onClick={() => {
              navigate('/explorer?filter=Wellness');
            }}
          >
            Accede a nuevos cursos de Bienestar Personal
          </div>
        </div>
        <div className="p-3 flex-1">
          <div
            className="bg-primary-700 p-3 rounded-lg text-sm font-bold text-center hover:bg-primary-800"
            onClick={() => {
              navigate('/diagnosticador/selector');
            }}
          >
            Demuestra cuánto sabes de Advanced Analytics. Ir al Test
          </div>
        </div>
        <div className="p-3 flex-1">
          <Link
            target="_blank"
            to="https://docs.google.com/forms/d/e/1FAIpQLSeHRRNhHreKp9rEh1PRcIBr-FC-prAxFOWdkgP7XtiuHlDyOQ/viewform"
          >
            <div className="bg-primary-700 p-3 rounded-lg text-sm font-bold text-center hover:bg-primary-800">
              Quiero sumarme a co-crear Open KX{' '}
            </div>
          </Link>
        </div>
      </div>

      <h2 className="mt-10 text-xl font-semibold">Contenido para ti</h2>
      <div className="mt-4 grid grid-cols-1 sm:grid-cols:3 lg:grid-cols-3 gap-3">
        {contentsQuery.isLoading && <p>Loading...</p>}
        {contentsQuery.data?.map((content: any) => (
          <Link
            className="w-54"
            key={content.id}
            to={`/explorer/${content.id}`}
          >
            <ContentCard content={content} />
          </Link>
        ))}
      </div>
    </div>
  );
  return withNavbar({ children: dispatcherContent });
};

export default Dashboard;
