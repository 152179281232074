import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { List } from '../components/CardList/CardList';
import withNavbar from '../../core/handlers/withNavbar';
import { useQuery } from '@tanstack/react-query';
import Favorites from '../components/Favorites/handlers/FavoritesRow';
import { debounce, flatMap, groupBy, map } from 'lodash';
import { useUser } from '../../core/feature-user/provider/userProvider';
import { Button } from '@material-tailwind/react';
import SearchBar from '../components/SearchBar';
import CardGrid from '../components/CardGrid';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Alert from 'src/app/ui/Alert';
import BotIcon from 'src/assets/icons/bot-icon.svg';
import api from 'src/app/core/api/apiProvider';

const StyledHeader = styled.h2`
  font-size: clamp(2rem, 13vw, 8rem);
  line-height: 1;
  font-weight: 400;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 5vh 10vw;
`;

const StyledListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin: 5vh 0;
`;

function BrowserTitle() {
  const { userAccountInfo } = useUser();
  const navigate = useNavigate();
  return (
    <StyledTitleContainer>
      <div>
        <StyledHeader>
          Hola{' '}
          <span className="text-label-alt">{userAccountInfo?.public_name}</span>
        </StyledHeader>
        <StyledHeader>Wiki Insiders</StyledHeader>
      </div>

      <div className="flex gap-12">
        <p className="text-label-alt">
          Gracias por acceder a Open KX 1ra generación. Actualmente nos
          encontramos en la fase de prelanzamiento. Cualquier feedback, reporte
          de problema o propuesta de mejora será MUY bienvenida.
        </p>
      </div>
      <div className="flex gap-12">
        <Button
          onClick={() => {
            navigate('/diagnosticador');
          }}
        >
          START
        </Button>
      </div>
    </StyledTitleContainer>
  );
}

const Browser = () => {
  const [searchInput, setSearchInput] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filterTerm, setFilterTerm] = useState('');
  let [searchParams, setSearchParams] = useSearchParams();

  const debounceSearchTerm = useCallback(
    debounce((param: string) => {
      setSearchTerm(param);
    }, 500),
    []
  );
  const { userInfo, userAccountInfo } = useUser();
  const { data, isFetching } = useQuery({
    queryKey: ['cardList'],
    queryFn: async () => {
      const { data } = await api.get(
        `${import.meta.env.VITE_API_URL}/recommendations/contents`
      );

      return data;
    },
  });

  const searchQuery = useQuery({
    queryKey: ['searchTerm', searchTerm],
    queryFn: async () => {
      if (searchTerm !== '') {
        const { data } = await api.get(
          `${
            import.meta.env.VITE_API_URL
          }/contents/contents_search/?query_param=${searchTerm}`
        );
        return data;
      }
      return null;
    },
    enabled: !!searchTerm,
  });

  useEffect(() => {
    if (searchParams.get('search')) {
      debounceSearchTerm(searchParams.get('search') || '');
      setSearchInput(searchParams.get('search') || '');
    }
    if (searchParams.get('filter')) {
      setFilterTerm(searchParams.get('filter') || '');
    }
  });

  const handleSearch = (param: string) => {
    setSearchParams({ search: param });
    setSearchInput(param);
    debounceSearchTerm(param);
  };

  const handleFilter = (param: string) => {
    setSearchParams({ filter: param });
    setFilterTerm(param);
  };

  const values = flatMap(data, (item) => {
    if (Array.isArray(item.industry)) {
      return item.industry.map((industry: any) => ({
        ...item,
        industry: industry.industry,
      }));
    } else {
      return { ...item };
    }
  });

  const browserContent = (
    <section className="container mx-auto px-3 lg:px-0">
      <StyledListContainer>
        <Alert image={BotIcon}>
          Hola {userAccountInfo?.public_name}! Gracias por acceder a OpenKX 1ra
          generación. Actualmente nos encontramos en la fase de prelanzamiento.
          Cualquier feedback, reporte de problema o propuesta de mejora será MUY
          bienvenida.
          <a
            className="self-center"
            href="https://forms.gle/T2ELLU6vzwfC9RY9A"
            target="_blank"
          >
            <Button color="white" variant="outlined" type="button">
              Feedback
            </Button>
          </a>
        </Alert>
        <SearchBar
          handleSearch={handleSearch}
          handleFilter={handleFilter}
          activeFilter={filterTerm}
          searchTerm={searchInput}
        />
        {searchTerm === '' && filterTerm === '' && (
          <Favorites contents={data} isFetching={isFetching} />
        )}
        {searchTerm === '' &&
          filterTerm === '' &&
          map(groupBy(values, 'industry'), (group) => {
            return (
              <List
                key={`list-${group[0].industry}`}
                data={group}
                isFetching={isFetching}
                title={group[0].industry}
                handleFilter={handleFilter}
              />
            );
          })}
        {searchTerm !== '' && (
          <CardGrid
            id="card-grid"
            data={searchQuery.data}
            isFetching={searchQuery.isFetching}
            searchParam={searchTerm}
          />
        )}
        {filterTerm !== '' && (
          <CardGrid
            id="card-grid"
            data={values.filter((v) => v.industry === filterTerm)}
            isFetching={isFetching}
            filterParam={filterTerm}
          />
        )}
      </StyledListContainer>
    </section>
  );

  return withNavbar({ children: browserContent });
};

export default Browser;
