export const BENCHMARK = {
    "C_LEVEL": [
        { "name": "Software Development", "value": 1 },
        { "name": "Tableau", "value": 2 },
        { "name": "Python", "value": 2 },
        { "name": "Customer segmentation", "value": 4 },
        { "name": "AI Integration", "value": 3 },
        { "name": "Data", "value": 5 },
        { "name": "Customer Journey", "value": 3 },
        { "name": "Market Research", "value": 5 },
        { "name": "Business Development", "value": 5 },
        { "name": "Data Visualisation", "value": 4 },
        { "name": "Customer Adquisition", "value": 4 },
        { "name": "Employee Engagement", "value": 4 },
        { "name": "Social Impact", "value": 5 },
        { "name": "Talent Management", "value": 5 },
        { "name": "Strategic Planning", "value": 5 },
        { "name": "Machine Learning", "value": 3 },
        { "name": "Predictive Analytics", "value": 3 },
        { "name": "Tableau", "value": 2 },
        { "name": "Power BI", "value": 3 },
        { "name": "R", "value": 3 },
        { "name": "Wellness", "value": 4 },
        { "name": "Security", "value": 3 },
        { "name": "Cibersecurity", "value": 2 },
        { "name": "D3", "value": 3 },
        { "name": "Survey Monkey", "value": 3 },
        { "name": "Cloud", "value": 4 },
        { "name": "ERP", "value": 3 },
        { "name": "IT Strategy planning", "value": 4 },
        { "name": "Program Management", "value": 4 },
        { "name": "Productivity", "value": 4 },
        { "name": "Social Networks", "value": 4 },
        { "name": "Networking", "value": 5 },
        { "name": "Python", "value": 2 },
        { "name": "Pricing", "value": 4 },
        { "name": "Segmentation", "value": 4 },
        { "name": "Risk Management", "value": 5 },
        { "name": "Technology Due Diligence", "value": 4 },
        { "name": "GDPR", "value": 4 },
        { "name": "Data Governance", "value": 3 },
        { "name": "Benefits & Compensations", "value": 4 },
        { "name": "Change Management", "value": 3 },
        { "name": "Diversity & Inclusion", "value": 4 },
        { "name": "Culture", "value": 4 },
        { "name": "Innovation", "value": 5 },
        { "name": "Cost", "value": 4 },
        { "name": "Logistics", "value": 5 },
        { "name": "Quality assurance", "value": 2 },
        { "name": "IT maintenance", "value": 4 },
        { "name": "Green IT", "value": 4 },
        { "name": "Clean Technology", "value": 5 },
        { "name": "Resource-efficiency strategy", "value": 4 },
        { "name": "Resource-efficiency implementation", "value": 4 },
        { "name": "Customer Satisfaction", "value": 3 },
        { "name": "Operational efficiency", "value": 4 },
        { "name": "Product Management", "value": 3 },
        { "name": "Test Capacity", "value": 3 },
        { "name": "Organisational training & development", "value": 4 }
    ],
    "Director": [
        { "name": "Data & Analytics", "value": 3 },
        { "name": "Customer Journey", "value": 4 },
        { "name": "Machine Learning", "value": 4 },
        { "name": "AI Integration", "value": 4 },
        { "name": "Predictive Analytics", "value": 3 },
        { "name": "Power BI", "value": 4 },
        { "name": "Wellness", "value": 4 },
        { "name": "Security", "value": 3 },
        { "name": "Cibersecurity", "value": 3 },
        { "name": "D3", "value": 3 },
        { "name": "Market Research", "value": 5 },
        { "name": "Survey Monkey", "value": 3 },
        { "name": "ERP", "value": 4 },
        { "name": "IT Strategy planning", "value": 5 },
        { "name": "Program Management", "value": 5 },
        { "name": "Social Networks", "value": 5 },
        { "name": "Networking", "value": 5 },
        { "name": "Python", "value": 3 },
        { "name": "Pricing", "value": 5 },
        { "name": "Segmentation", "value": 4 },
        { "name": "Risk Management", "value": 4 },
        { "name": "Technology Due Diligence", "value": 5 },
        { "name": "GDPR", "value": 5 },
        { "name": "Data Governance", "value": 4 },
        { "name": "Benefits & Compensations", "value": 5 },
        { "name": "Change Management", "value": 4 },
        { "name": "Diversity & Inclusion", "value": 5 },
        { "name": "Culture", "value": 5 },
        { "name": "Innovation", "value": 5 },
        { "name": "Cost", "value": 5 },
        { "name": "Logistics", "value": 4 },
        { "name": "IT maintenance", "value": 5 },
        { "name": "Green IT", "value": 4 },
        { "name": "Clean Technology", "value": 4 },
        { "name": "Resource-efficiency strategy", "value": 5 },
        { "name": "Resource-efficiency implementation", "value": 5 },
        { "name": "Customer Satisfaction", "value": 5 },
        { "name": "Operational efficiency", "value": 5 },
        { "name": "Product Management", "value": 4 },
        { "name": "Test Capacity", "value": 4 },
        { "name": "Organisational training & development", "value": 5 }
    ],
    "Manager": [
        { "name": "Data & Analytics", "value": 4 },
        { "name": "Software Development", "value": 1 },
        { "name": "Customer segmentation", "value": 5 },
        { "name": "AI Integration", "value": 5 },
        { "name": "Data", "value": 5 },
        { "name": "Market Research", "value": 4 },
        { "name": "Business Development", "value": 4 },
        { "name": "Data Visualisation", "value": 5 },
        { "name": "Customer Adquisition", "value": 5 },
        { "name": "Employee Engagement", "value": 5 },
        { "name": "Social Impact", "value": 5 },
        { "name": "Talent Management", "value": 5 },
        { "name": "Strategic Planning", "value": 5 },
        { "name": "Machine Learning", "value": 4 },
        { "name": "Predictive Analytics", "value": 4 },
        { "name": "Tableau", "value": 3 },
        { "name": "Power BI", "value": 4 },
        { "name": "R", "value": 3 },
        { "name": "Wellness", "value": 4 },
        { "name": "Security", "value": 4 },
        { "name": "Cibersecurity", "value": 3 },
        { "name": "D3", "value": 3 },
        { "name": "Survey Monkey", "value": 3 },
        { "name": "Cloud", "value": 5 },
        { "name": "ERP", "value": 4 },
        { "name": "IT Strategy planning", "value": 5 },
        { "name": "Program Management", "value": 5 },
        { "name": "Productivity", "value": 5 },
        { "name": "Social Networks", "value": 5 },
        { "name": "Networking", "value": 5 },
        { "name": "Python", "value": 3 },
        { "name": "Pricing", "value": 5 },
        { "name": "Segmentation", "value": 4 },
        { "name": "Risk Management", "value": 4 },
        { "name": "Technology Due Diligence", "value": 5 },
        { "name": "GDPR", "value": 5 },
        { "name": "Data Governance", "value": 4 },
        { "name": "Benefits & Compensations", "value": 5 },
        { "name": "Change Management", "value": 4 },
        { "name": "Diversity & Inclusion", "value": 5 },
        { "name": "Culture", "value": 5 },
        { "name": "Innovation", "value": 5 },
        { "name": "Cost", "value": 5 },
        { "name": "Logistics", "value": 4 },
        { "name": "IT maintenance", "value": 5 },
        { "name": "Green IT", "value": 4 },
        { "name": "Clean Technology", "value": 4 },
        { "name": "Resource-efficiency strategy", "value": 5 },
        { "name": "Resource-efficiency implementation", "value": 5 },
        { "name": "Customer Satisfaction", "value": 5 },
        { "name": "Operational efficiency", "value": 5 },
        { "name": "Product Management", "value": 4 },
        { "name": "Test Capacity", "value": 4 },
        { "name": "Organisational training & development", "value": 5 }
    ],
    "Jefe": [
        { "name": "Customer segmentation", "value": 5 },
        { "name": "AI Integration", "value": 4 },
        { "name": "Data", "value": 5 },
        { "name": "Customer Journey", "value": 4 },
        { "name": "Market Research", "value": 3 },
        { "name": "Business Development", "value": 3 },
        { "name": "Data Visualisation", "value": 5 },
        { "name": "Customer Adquisition", "value": 4 },
        { "name": "Employee Engagement", "value": 3 },
        { "name": "Social Impact", "value": 4 },
        { "name": "Talent Management", "value": 3 },
        { "name": "Strategic Planning", "value": 3 },
        { "name": "Machine Learning", "value": 5 },
        { "name": "Predictive Analytics", "value": 5 },
        { "name": "Tableau", "value": 3 },
        { "name": "Power BI", "value": 5 },
        { "name": "R", "value": 4 },
        { "name": "Wellness", "value": 4 },
        { "name": "Security", "value": 4 },
        { "name": "Cibersecurity", "value": 3 },
        { "name": "D3", "value": 3 },
        { "name": "Survey Monkey", "value": 4 },
        { "name": "Cloud", "value": 5 },
        { "name": "ERP", "value": 4 },
        { "name": "IT Strategy planning", "value": 4 },
        { "name": "Program Management", "value": 4 },
        { "name": "Productivity", "value": 5 },
        { "name": "Social Networks", "value": 5 },
        { "name": "Networking", "value": 3 },
        { "name": "Python", "value": 5 },
        { "name": "Pricing", "value": 4 },
        { "name": "Segmentation", "value": 4 },
        { "name": "Risk Management", "value": 4 },
        { "name": "Technology Due Diligence", "value": 4 },
        { "name": "GDPR", "value": 5 },
        { "name": "Data Governance", "value": 4 },
        { "name": "Benefits & Compensations", "value": 5 },
        { "name": "Change Management", "value": 3 },
        { "name": "Diversity & Inclusion", "value": 4 },
        { "name": "Culture", "value": 4 },
        { "name": "Innovation", "value": 4 },
        { "name": "Cost", "value": 5 },
        { "name": "Logistics", "value": 3 },
        { "name": "Quality assurance", "value": 4 },
        { "name": "IT maintenance", "value": 4 },
        { "name": "Green IT", "value": 4 },
        { "name": "Clean Technology", "value": 4 },
        { "name": "Resource-efficiency strategy", "value": 5 },
        { "name": "Resource-efficiency implementation", "value": 4 },
        { "name": "Customer Satisfaction", "value": 4 },
        { "name": "Operational efficiency", "value": 4 },
        { "name": "Product Management", "value": 5 },
        { "name": "Test Capacity", "value": 5 },
        { "name": "Organisational training & development", "value": 4 }
    ]
}
