import {
  EyeIcon,
  PencilIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Chip,
  IconButton,
  Spinner,
  Tooltip,
  Typography,
} from '@material-tailwind/react';
import { useQuery } from '@tanstack/react-query';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'src/app/core/api/apiProvider';
import { useUser } from 'src/app/core/feature-user/provider/userProvider';
import withNavbar from 'src/app/core/handlers/withNavbar';
import Alert from 'src/app/ui/Alert';
import Button from 'src/app/ui/Button';
import BotIcon from 'src/assets/icons/bot-icon.svg';

const TABLE_HEAD = ['Id', 'Nombre', 'Tipo', 'Estado', 'Fecha', ''];

const ContentTable: FunctionComponent<any> = (props: any) => {
  const navigate = useNavigate();
  const { data } = props;
  return (
    <table className="mt-4 w-full min-w-max table-auto text-left">
      <thead>
        <tr>
          {TABLE_HEAD.map((head) => (
            <th
              key={head}
              className="border-y border-blue-gray-100 bg-gray-700 p-4"
            >
              <Typography
                variant="small"
                color="white"
                className="font-normal leading-none opacity-70"
              >
                {head}
              </Typography>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map(
          ({ id, name, type, created_at, status }: any, index: number) => {
            const isLast = index === data.length - 1;
            const classes = isLast ? 'p-4' : 'p-4 border-b border-blue-gray-50';

            return (
              <tr key={id}>
                <td className={classes}>
                  <div className="flex items-center gap-3">
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="white"
                        className="font-normal"
                      >
                        {id}
                      </Typography>
                    </div>
                  </div>
                </td>
                <td className={classes}>
                  <div className="flex items-center gap-3">
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="white"
                        className="font-normal"
                      >
                        {name}
                      </Typography>
                    </div>
                  </div>
                </td>
                <td className={classes}>
                  <div className="flex items-center gap-3">
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="white"
                        className="font-normal"
                      >
                        {type}
                      </Typography>
                    </div>
                  </div>
                </td>
                <td className={classes}>
                  <div className="w-max">
                    <Chip
                      variant="ghost"
                      size="sm"
                      value={status ? 'publicado' : 'en revisión'}
                      color={status ? 'green' : 'blue-gray'}
                    />
                  </div>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="white"
                    className="font-normal"
                  >
                    {new Date(created_at).toLocaleDateString()}
                  </Typography>
                </td>
                <td className={classes}>
                  <Tooltip content="View Content">
                    <IconButton
                      variant="text"
                      onClick={() => navigate(`/explorer/${id}`)}
                    >
                      <EyeIcon className="h-4 w-4" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip content="Edit Content">
                    <IconButton
                      variant="text"
                      onClick={() => navigate(`edit/${id}`)}
                    >
                      <PencilIcon className="h-4 w-4" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip content="Delete Content">
                    <IconButton variant="text">
                      <TrashIcon className="h-4 w-4" />
                    </IconButton>
                  </Tooltip>
                </td>
              </tr>
            );
          }
        )}
      </tbody>
    </table>
  );
};

const Content: FunctionComponent<any> = () => {
  const navigate = useNavigate();
  const { userInfo, userAccountInfo } = useUser();

  const { data, isFetching } = useQuery({
    queryKey: ['content'],
    queryFn: async () => {
      const response = await api.get(
        `${import.meta.env.VITE_API_URL}/contents/user_contents/`
      );
      return response.data;
    },
  });

  const pageContent = (
    <>
      <div className="my-5 container mx-auto">
        <div className="mt-6">
          <Alert image={BotIcon}>
            Hola {userAccountInfo?.public_name}! Gracias por acceder a Open KX
            1ra generación. Actualmente nos encontramos en la fase de
            prelanzamiento. Cualquier feedback, reporte de problema o propuesta
            de mejora será MUY bienvenida.
            <a
              className="self-center"
              href="https://forms.gle/T2ELLU6vzwfC9RY9A"
              target="_blank"
            >
              <Button outline>Feedback</Button>
            </a>
          </Alert>
        </div>

        <div className="flex justify-between items-center">
          <div>
            <h2 className="text-4xl font-bold mt-10 mb-7">Tu Contenido</h2>
          </div>
          <div className="flex gap-3">
            {/*<Button variant="secondary" outline>
              View all
            </Button>*/}
            <Button primary onClick={() => navigate('new')}>
              <PlusCircleIcon strokeWidth={2} className="h-4 w-4" /> Agregar
              Contenido
            </Button>
          </div>
        </div>

        <Card className="h-full w-full bg-gray-800">
          <CardBody className="overflow-y-auto px-0">
            {isFetching && (
              <div className="flex justify-center">
                <Spinner className="h-8 w-8"></Spinner>
              </div>
            )}
            {data && data.length > 0 && <ContentTable data={data} />}
            {data && data.length === 0 && (
              <div className="flex justify-center">
                <Typography
                  variant="small"
                  color="white"
                  className="font-normal"
                >
                  No has publicado ningún contenido.
                </Typography>
              </div>
            )}
          </CardBody>
          {/*<CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
            <Typography
              variant="small"
              color="blue-gray"
              className="font-normal"
            >
              Page 1 of 10
            </Typography>
            <div className="flex gap-2">
              <Button variant="secondary" outline>
                Previous
              </Button>
              <Button outline variant="secondary">
                Next
              </Button>
            </div>
          </CardFooter>*/}
        </Card>
      </div>
    </>
  );

  return withNavbar({ children: pageContent });
};

export default Content;
