import { Input } from '@material-tailwind/react';
import { FunctionComponent, useState } from 'react';
import withNavbar from 'src/app/core/handlers/withNavbar';
import Button from 'src/app/ui/Button';

const ChangePassword: FunctionComponent<any> = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    console.log('submit');
  };

  const pageContent = (
    <div className="container mx-auto">
      <div className="border border-tertiary rounded m-3 p-5">
        <form onSubmit={handleSubmit} className="flex flex-col gap-5">
          <Input
            label="Contraseña Actual"
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            required
          />
          <Input
            label="Nueva Contraseña"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <Input
            label="Confirmar Contraseña Nueva"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <Button type="submit" variant="primary">
            Cambiar contraseña
          </Button>
        </form>
      </div>
    </div>
  );
  return withNavbar({ children: pageContent });
};

export default ChangePassword;
